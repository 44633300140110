.head[data-v-77d33a90] {
  padding: 10px;
}
.content[data-v-77d33a90] {
  padding: 0 10px;
}
.opera-btn-box[data-v-77d33a90] {
  text-align: right !important;
  margin-top: 10px;
}
.content_select[data-v-77d33a90] {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}